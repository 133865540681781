import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import * as moment from 'moment';
import { HelpService } from '../../services/help.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
   dateRangeForm: FormGroup;
  empDetails: any;
  headerTitle: boolean = true;
  src = '../../../../assets/home_png_icons/';
  images:any[] = [];
  hasPermission = false;

  constructor(private fb: FormBuilder, private apiService: ApiService, private helpService: HelpService, private router: Router) {

    

    this.dateRangeForm = this.fb.group({
      dateRange: this.fb.group({
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
      }, { validator: this.dateRangeValidator })
    });

  }
  ngOnInit(): void {
    this.helpService.managerPermissionData$.subscribe((flag) => {
      
      // Only set hasPermission if flag is not null or undefined
      if (flag === '1') {
        this.hasPermission = false; // User has permission
      } else {
        this.hasPermission = true; // User does not have permission or flag is null
      }

      // Initialize the images array after `hasPermission` is set
      this.images = [
        { name: 'Timesheet', src: `${this.src}timeSheet_2.png`, route: '/user/user-timesheet', disabled: false },
        { name: 'Timesheet Approval', src: `${this.src}timesheetApproval.png`, route: '/user/manager-view', disabled: this.hasPermission },
        { name: 'DU University', src: `${this.src}TrainingOpt2.png`, route: '/user/trainings', disabled: false },
        // { name: 'DU Docs', src: `${this.src}documents.png`, route: '/user/trainings', disabled: false },
        // { name: 'DU MoM', src: `${this.src}Mom1.png`, route: '/user/trainings', disabled: false },
      ];
    });
  }
  
  // Check permissions for each image and set 'disabled' based on permissions will implement once BE is ready
  checkPermissions() { 
    this.images.forEach(image => {
      // Dynamically set 'disabled' based on permissions
      //image.disabled = !this.permissionService.hasPermission(image.route);
    });
  }

  onImageClick(routePath: string): void {
    //route as per image name
    const Route = routePath
    if(Route) this.router.navigate([Route]);
  }

  dateRangeValidator(group: FormGroup) {
    const startDate = group.get('startDate')?.value;
    const endDate = group.get('endDate')?.value;

    return endDate && startDate && endDate < startDate ? { invalidDateRange: true } : null;
  }

  onSubmit() {
    this.empDetails = sessionStorage.getItem('EmployeeData');
    this.empDetails = (JSON.parse(this.empDetails))[0];
    if (this.dateRangeForm.valid) {
      const { startDate, endDate } = this.dateRangeForm.get('dateRange')?.value;
      let postData:any = {
        "employee_key": this.empDetails.employee_key,
        "week_start_dt": moment(startDate).format('yyyy-MM-DD'),
        "week_end_dt": moment(endDate).format('yyyy-MM-DD'),
      }
      // this.helpService.selectedDate 
      localStorage.setItem('selectedDate',moment(startDate).format(' MMM DD -') + moment(endDate).format('MMM DD YYYY')) 
      sessionStorage.setItem('selectedDate', JSON.stringify(postData) )
      this.getMasterTimesheet(postData)
    }
    this.router.navigate(['/user/user-timesheet']);
  }
  getMasterTimesheet(postData: any) {

    this.apiService.getMasterTimesheet(postData).subscribe((res: any) => {
      let data:any= res ? res : [];
      sessionStorage.setItem('timeSheetMasterData', JSON.stringify(data) )
      

    })
  }
}
