import { Component } from '@angular/core';
import { ManagerService } from 'src/app/services/manager-service.service';

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.scss']
})
export class TrainingsComponent {

  headerTitle: boolean = true;

  videos:any[] = [];

  selectedVideo:any = "";
  selectedVideoPath = "";

  constructor(private managerService:ManagerService) { }

  ngOnInit(): void {
   //  this.getVideoListName();
    this.gettrainningStructure();
  }
  playVideo(video:any) {
    this.selectedVideo = video;
  }

  getVideoListName() {
    this.managerService.getVideoLink().subscribe((res:any) => {
      //console.log(res)
      this.videos = res;
    })
  }
  treeData: any[] = [];
  gettrainningStructure() {
    this.managerService.gettrainingStructured().subscribe((res:any) => {
      console.log(res)
      this.treeData = this.transformToTree(res);
      console.log("structuredData",this.treeData);
      
    })
  }
  errorMessage: string | null = null;
  currentVideoName:string = "";

  isMp4(file: string): boolean {
    return !!file && file.endsWith('.mp4');
  }
  
  isPdf(file: string): boolean {
    return !!file && file.endsWith('.pdf');
  }
  
  isPpt(file: string): boolean {
    return !!file && (file.endsWith('.ppt') || file.endsWith('.pptx'));
  }

  getSelectedVideo(video: any) {
    this.selectedVideo = "";
    this.selectedVideoPath = video;
    let payload = { "file_path": video };
    
    const filePath = this.selectedVideoPath;

    // Extract the filename
    const fileName = filePath.substring(filePath.lastIndexOf('/') + 1, filePath.lastIndexOf('.'));  // Output: "2. Welcome to Data Unveil-Business Overview (Session2)"

    // Remove the number and space at the start
    this.currentVideoName = fileName.replace(/^\d+\.\s*/, '');

    this.managerService.getSelectedVideo(payload).subscribe({
        next: (url: string) => {
           // console.log('Received URL:', url); // Log response URL
            this.selectedVideo = url;
            this.errorMessage = null; // Clear any previous errors
        },
        error: (error) => {
            console.error('Error fetching video URL:', error.error.text); // Detailed error log
           this.selectedVideo = error.error.text
        }
    });
  }

  // Transform flat data to tree structure
  transformToTree(data: any[]): any[] {
    const tree:any = [];
    const lookup: any = {};

    data.forEach((item) => {
      lookup[item.training_id] = { ...item, children: [] };
    });

    data.forEach((item) => {
      if (item.parent_node_id) {
        lookup[item.parent_node_id].children.push(lookup[item.training_id]);
      } else {
        tree.push(lookup[item.training_id]);
      }
    });

    return tree;
  }

  // Function to call API with file path
  // fetchFile(filePath: string): void {
  //   const apiUrl = `YOUR_FILE_FETCH_API_ENDPOINT`;
  //   this.http.post(apiUrl, { file_path: filePath }).subscribe(
  //     (response:any) => {
  //       console.log('File fetched successfully:', response);
  //       // Handle video/ppt loading logic here
  //     },
  //     (error) => {
  //       console.error('Error fetching file:', error);
  //     }
  //   );
  // }
}
