<div class="modal-overlay" (click)="close()"></div>
<div class="modal-content">
    <h3 style="margin-left: 25px;">User Manual</h3>
    <mat-icon class="close-icon" (click)="close()">close</mat-icon>
    <div mat-dialog-content>
        <div *ngIf="loading" class="loading-spinner">
          <mat-spinner></mat-spinner>
        </div>
      
        <ng-container *ngIf="!loading">
          <ng-container *ngIf="data.type === 'docs'; else videoContent">
            <iframe
              *ngIf="sanitizedUrl"
              [src]="sanitizedUrl"
              width="100%"
              height="600px"
              frameborder="0"
            ></iframe>
          </ng-container>
      
          <ng-template #videoContent>
            <video *ngIf="sanitizedUrl" controls width="100%" height="350px">
              <source [src]="sanitizedUrl" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </ng-template>
        </ng-container>
      </div>
      

</div>