import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { HelpService } from 'src/app/services/help.service';
import { SessionService } from 'src/app/services/session.service';
import { UserManualComponent } from './user-manual/user-manual.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userName: string = ''
  date:any;
  @Input() headerTitle: boolean = false;
  isBurgerMenu:boolean = false;
  src = '../../../../assets/home_png_icons/';
  @ViewChild('sidenav', { static: false }) sideNav!: ElementRef;
  constructor(private sessionService: SessionService,public helpService :HelpService, private router: Router,private dialog: MatDialog) { }
  status:any =  [
    {
      "status_key": 1,
      "status_desc": "Pending"
    },
    {
      "status_key": 2,
      "status_desc": "Submitted"
    },
    {
      "status_key": 3,
      "status_desc": "Approved"
    },
    {
      "status_key": 4,
      "status_desc": "Rejected"
    }
  ]

  navLinks: any[] = [];
  hasPermission = false;
  ngOnInit(): void {
    let url = this.router.url;
    this.isBurgerMenu = url.includes('home')
    this.helpService.managerPermissionData$.subscribe((flag) => {
      
      // Determine hasPermission based on the flag
      if (flag === '1') {
        this.hasPermission = false; // User has permission
      } else {
        this.hasPermission = true; // User does not have permission or flag is null
      }

      // Initialize the navLinks array after `hasPermission` is set
      this.navLinks = [
        { path: '/home', label: 'Home', imageUrl: `${this.src}house-svgrepo-com.png`, disabled: false },
        { path: '/user/user-timesheet', label: 'Timesheet', imageUrl: `${this.src}timeSheet_2.png`, disabled: false },
        { path: '/user/manager-view', label: 'Timesheet Approval', imageUrl: `${this.src}timesheetApproval.png`, disabled: this.hasPermission },
        { path: '/user/trainings', label: 'Training', imageUrl: `${this.src}TrainingOpt2.png`, disabled: false },
      ];
    });
    
    setTimeout(() => {
    
      let auth:any = sessionStorage.getItem('authUser')
      auth = JSON.parse(auth)
      this.userName =  auth.attributes.name 
      this.date = localStorage.getItem('selectedDate')
    }, 3000);

    this.overapplicationClick();
  }

  @ViewChild('logoutMenuTrigger') logoutMenuTrigger!: MatMenuTrigger;
  @ViewChild('helpMenuTrigger') helpMenuTrigger!: MatMenuTrigger;

  // Listen for clicks on the entire document
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    const isClickInsideLogoutMenu = (event.target as HTMLElement).closest('.left-section');
    const isClickInsideHelpMenu = (event.target as HTMLElement).closest('.svg-container');

    // Close logout menu if clicked outside it
    if (!isClickInsideLogoutMenu) {
      this.logoutMenuTrigger.closeMenu();
    }
    
    // Close help menu if clicked outside it
    if (!isClickInsideHelpMenu) {
      this.helpMenuTrigger.closeMenu();
    }
  }

  logout() {
    Auth.signOut();
    sessionStorage.clear();
    localStorage.clear()
    window.location.href = 'login';
  }
  
  overapplicationClick() {
    this.helpService.mouseClick$.subscribe((event: MouseEvent) => {
      // Check if the click is outside the sidenav
      const sidenavElement = document.getElementById('sidenav');
      if (sidenavElement && !sidenavElement.contains(event.target as Node)) {
       // The click happened outside the sidenav
       this.closeSideNav();  // Close the sidenav
     } 
   });
  }
  getGreeting(): string {
    const now = new Date();
    const hours = now.getHours();

    if (hours < 12) {
      return 'Good morning';
    } else if (hours < 18) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  }

  sideNavOpen = false;

  toggleSideNav() {
    this.sideNavOpen = !this.sideNavOpen;
  }

  closeSideNav() {
    this.sideNavOpen = false;
  }

  onLinkClick(event: Event, link: any): void {
   // console.log(link);
  
    // Check if the link is disabled
    if (link.disabled) {
      event.preventDefault();  // Prevent default anchor behavior
      event.stopPropagation(); // Stop event bubbling
      return;                  // Exit the function
    }
    
    // Proceed with the navigation if not disabled
    this.closeSideNav(); // Optional: Close side navigation if desired
  }

  isActiveRoute(route: string): boolean {
    return this.router.url === route;
  }

  openDialog(type: string): void {
    this.dialog.open(UserManualComponent, {
      data: { type },
      width: '80%',
      height: '80%',
    });
  }
}
