<app-header [headerTitle]="headerTitle"></app-header>

<div class="main-container">
    <div class="left-video-list-div">
        <!-- <ul class="video-list">
            <li  [ngClass]="{'selected': selectedVideoPath === video.file_path}" *ngFor="let video of videos" (click)="getSelectedVideo(video.file_path)">
              {{ video.file_name }}
            </li>
        </ul> -->

        <mat-accordion>
            <ng-container *ngFor="let node of treeData">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  {{ node.training_node }}
                </mat-expansion-panel-header>
          
                <!-- Child nodes -->
                <ng-container *ngIf="node.children.length > 0">
                  <div class="child-nodes">
                    <ng-container *ngFor="let child of node.children">
                      <div class="child-item">
                        <button mat-button color="primary" (click)="getSelectedVideo(child.file_path)" >
                          {{ child.training_node }}
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
          
          
    </div>
    <div class="right-video-div">
        <h4 style="text-align: center;">{{currentVideoName ? currentVideoName : ''}}</h4>
        <video *ngIf="selectedVideo" controls class="video">
            <source [attr.src]="selectedVideo" type="video/mp4">
            Your browser does not support the video tag.
        </video>

         <!-- Video -->
    <!-- Video (MP4) -->
    <!-- <video *ngIf="isMp4(selectedVideo)" controls class="media">
        <source [attr.src]="selectedVideo" type="video/mp4">
        Your browser does not support the video tag.
    </video> -->

    <!-- PDF -->
    <!-- <embed *ngIf="isPdf(selectedVideo)" [src]="selectedVideo" type="application/pdf" width="100%" height="600px" /> -->

    <!-- PowerPoint (PPT or PPTX) -->
    <!-- <iframe *ngIf="isPpt(selectedVideo)" 
            [src]="'https://docs.google.com/viewer?url=' + selectedVideo + '&embedded=true'" 
            width="100%" 
            height="600px" 
            allowfullscreen>
    </iframe> -->
    </div>
</div>