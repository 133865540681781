import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.scss']
})
export class UserManualComponent {
  sanitizedUrl: SafeResourceUrl | null = null;
  loading = true; // Show a loading indicator
  videoUrl: SafeResourceUrl | null = null;
  documentUrl: SafeResourceUrl | null = null;
  isVideo = false;
  isDocument = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: string },
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<UserManualComponent>
  ) {}

  ngOnInit(): void {
    this.fetchContent();
  }

  fetchContent(): void {
    let apiUrl = '';
    if (this.data.type === 'docs') {
      apiUrl = 'https://platform-stage-api.dataunveil.com/hr-api/user/get-help-doc';
    } else if (this.data.type === 'video') {
      apiUrl = 'https://platform-stage-api.dataunveil.com/hr-api/user/get-help-video';
    }
  
    // API request
    this.http.get(apiUrl, { responseType: 'text' }).subscribe({
      next: (response) => {
        console.log('API Response:', response); // Debugging response
        // If the content is a document, wrap it with Google Docs Viewer
        if (this.data.type === 'docs') {
          this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            `https://docs.google.com/gview?url=${encodeURIComponent(response)}&embedded=true`
          );
        } else {
          // For videos, directly use the AWS link
          this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response);
        }
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching content:', error); // Log error details
        this.loading = false;
      }
    });
  }
  
  close(): void {
    // Logic to close the popup
    this.dialogRef.close(); // If using Angular Material Dialog
  }
}
