import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
import { UserRoutingModule } from './user.routing.module';
import { UserTimesheetComponent } from './user-timesheet/user-timesheet.component';
import { MaterialModule } from 'src/app/material.module';
import { AgGridModule } from 'ag-grid-angular';
import { MatSelectCellRendererComponent } from './user-timesheet/mat-select-cell-renderer';
import { MatInputCellRendererComponent } from './user-timesheet/mat-input-cell-renderer';
import { HomeComponent } from '../home/home.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HeaderComponent } from '../layout/header/header.component';
import { TimeTrackingDialogComponent } from './time-tracking-dialog/time-tracking-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from './loader/loader.component';
import { AutocompleteCellRenderer } from './user-timesheet/mat-autocomplete-cell-render';
import { CustomHeaderComponent } from './user-timesheet/custom-header.component';
import { ManagerviewComponent } from '../home/manager-vies/managerview/managerview.component';
import { TrainingsComponent } from './trainings/trainings.component';
import { ApprovaltimesheetComponent } from './approvaltimesheet/approvaltimesheet.component';
import { CloneweekComponent } from './user-timesheet/cloneweek/cloneweek.component';
import { UserManualComponent } from '../layout/header/user-manual/user-manual.component';

@NgModule({
  declarations: [
    UserTimesheetComponent,
    MatSelectCellRendererComponent,
    MatInputCellRendererComponent,
    HeaderComponent,
    TimeTrackingDialogComponent,
    LoaderComponent,
    AutocompleteCellRenderer,
    CustomHeaderComponent,
    ManagerviewComponent,
    TrainingsComponent,
    ApprovaltimesheetComponent,
    CloneweekComponent,
    UserManualComponent
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UserRoutingModule,
    FormsModule,
    MaterialModule,
    AgGridModule,
  ],
  exports:[
    UserTimesheetComponent,
    MatSelectCellRendererComponent,
    MatInputCellRendererComponent,
    HeaderComponent,
    TimeTrackingDialogComponent,
    LoaderComponent,
    AutocompleteCellRenderer,
    CustomHeaderComponent,
    ManagerviewComponent,
    TrainingsComponent,
    ApprovaltimesheetComponent,
    CloneweekComponent,
    UserManualComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class UserModule { }
