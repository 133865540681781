import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CellValueChangedEvent, ColDef, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { MatSelectCellRendererComponent } from './mat-select-cell-renderer';
import { MatInputCellRendererComponent } from './mat-input-cell-renderer';
import { SessionService } from 'src/app/services/session.service';
import { ApiService } from 'src/app/services/api.service';
import { CellChangedEvent } from 'ag-grid-community/dist/types/core/interfaces/iRowNode';
import { TimesheetService } from 'src/app/services/timesheet.service';
import  * as moment from 'moment';
import { LicenseManager } from 'ag-grid-enterprise'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HelpService } from '../../../services/help.service';
import { AlertDialogService } from 'src/app/services/alert-dialog.service';
import { AutocompleteCellRenderer } from './mat-autocomplete-cell-render';
import * as _ from "lodash";
import { CustomHeaderComponent } from './custom-header.component';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { ActivatedRoute } from '@angular/router';
import { ManagerService } from 'src/app/services/manager-service.service';
import { Subject, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-043433 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Data Unveil )_is_granted_a_( Single Application )_Developer_License_for_the_application_( SCRAII )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( SCRAII )_need_to_be_licensed___( SCRAII )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 7 June 2024 )____[v2]_MTcxNzcxNDgwMDAwMA==8211f96f946c59b6a3dfa3ea03a04f25")

import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';


// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';

const _moment = _rollupMoment || moment;

export const MY_FORMATS = { // custom format to display day name
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/** @title Datepicker with custom formats */


@Component({
  selector: 'app-user-timesheet',
  templateUrl: './user-timesheet.component.html',
  styleUrls: ['./user-timesheet.component.scss'],
  providers: [ //custom provider to calendar header day name 
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class UserTimesheetComponent implements OnInit {
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public grandTotalRow: "top" | "bottom" = "bottom";
  total: number | undefined;
  private gridApi!: GridApi;
  public gridOptions: GridOptions;
  dataSet: any;
  today: Date = new Date();
  empDetails: any;
  EmpName: string = '';
  designation: string = '';
  ClientData: any = [];
  fields: any = [];
  transformedClients: any;
  columnDefs!: ColDef[];
  rowData: any = []
  selectedweeks: any
  timeSheetMasterData: any
  dateRangeForm: FormGroup;
  endDate: any
  showtable: boolean = false
  firstChange: boolean = true;
  isLoading = false;
  initRowData:any=[]
  previousValue:any;
  toApprovalData:any;
  approvalStart_wk:string = '';
  approvalEnd_wk:string = '';
  private destroy$ = new Subject<void>();
  managerComments:string='';
  currentIndex: number = 0; // Start with the first record
  approvalDataLength: number = 0;
  dateValue: string | null = null;
  isModalOpen = false; // open clone week modal
  passToClone:any;
  @ViewChild('datepickerFooter', {static: false}) datepickerFooter!: ElementRef;
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    let navigate = this.helpService.arraysEqual(this.initRowData,this.rowData)
    if(!navigate && (this.helpService.status!='Submitted' || this.helpService.status!='Approved')){
      const message = 'You have unsaved changes. Do you really want to leave?';
      $event.returnValue = message;
    }
  }
  constructor(private timesheetService: TimesheetService, private fb: FormBuilder, private router: Router,
    private apiService: ApiService, public helpService: HelpService, public AlertDialog: AlertDialogService, private activeroute: ActivatedRoute,
    private managerService:ManagerService,public dialog: MatDialog) {
    this.helpService.status = '';
    this.helpService.selectedDate = '';
    this.isLoading = true
    this.apiService.getDoc('timesheet-fields').subscribe((res: any) => {
      this.fields = res ? res : [];
      this.loadFields(this.fields);
    })
    this.dateRangeForm = this.fb.group({
      startDate: [null, Validators.required]
    });


    this.gridOptions = {
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        editable: false,
        resizable: true,
        suppressHeaderMenuButton: true,
        // headerClass: 'text-center',
       
      },
      context: { componentParent: this },

    };

  }
  status:any =  [
    {
      "status_key": 1,
      "status_desc": "Pending"
    },
    {
      "status_key": 2,
      "status_desc": "Submitted"
    },
    {
      "status_key": 3,
      "status_desc": "Approved"
    },
    {
      "status_key": 4,
      "status_desc": "Rejected"
    }
  ]
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }
  getMonday(d: Date): Date {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
    return new Date(d.setDate(diff));
  }

  

  myFilter: (d: Date | null) => boolean = (d: Date | null): boolean => {
    if (!d) {
      return false;
    }
  //  console.log("my filter d", d);
    
   const day =  moment(d).day();
   //const day = d.getDay();
    const today = new Date();
    const currentMonday = this.getMonday(today);
    const minDate = new Date(currentMonday.getFullYear(), currentMonday.getMonth(), currentMonday.getDate() - 14);
    const maxDate = new Date(currentMonday.getFullYear(), currentMonday.getMonth(), currentMonday.getDate() + 14);

    let firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
    let pastTwoWeeksDetails = this.getPastWeekDetails(firstDayOfWeek, this.weekStatusDetails);
    let earliestWeek = this.getEarliestDateWithStatus(pastTwoWeeksDetails);
    
    if(this.weekStatusDetails.length ==0) { 
      let doJ =  new Date(this.empDetails.date_of_joining);
      if (doJ > firstDayOfWeek) {
        // Allow only the next two Mondays
        const nextMonday1 = new Date(firstDayOfWeek);
        nextMonday1.setDate(firstDayOfWeek.getDate() + 7);
        const nextMonday2 = new Date(nextMonday1);
        nextMonday2.setDate(nextMonday1.getDate() + 7);
        
        return moment(d).isSame(nextMonday1, 'day') || 
               moment(d).isSame(nextMonday2, 'day');
        // Allow the first day of the current week and the next two Mondays
        
      } else {
        // Allow only the next two Mondays
        const nextMonday1 = new Date(firstDayOfWeek);
        nextMonday1.setDate(firstDayOfWeek.getDate() + 7);
        const nextMonday2 = new Date(nextMonday1);
        nextMonday2.setDate(nextMonday1.getDate() + 7);
        
        return moment(d).isSame(firstDayOfWeek, 'day') || 
               moment(d).isSame(nextMonday1, 'day') || 
               moment(d).isSame(nextMonday2, 'day');
      }
     }

    if(earliestWeek == null || earliestWeek == undefined) {
      return day !== 0 && day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6 &&d >= minDate && d <= maxDate;
    } else {
      
      let inputDate:any = new Date(earliestWeek);
      inputDate = moment(inputDate).day();
      if (inputDate === 1) {
        // Allow only this Monday if `inputDate` equals 1 (Monday)
        return moment(d).isSame(new Date(earliestWeek), 'day');
      }
      return day !== 0 && day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6 &&d >= minDate && d <= maxDate;
    }
    // Return true for weekdays (Monday to Friday) and false for weekends (Saturday and Sunday)
  };

  // Custom date class for highlighting specific dates 
  //will implement once BE ready
  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view === 'month') {
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();

      // Create a map for weekStatus keyed by dates
      const weekStatusMap = new Map<string, number>();
      this.weekStatusDetails.forEach((weekStatus: { week_start_dt: string, status_key: number }) => {
          // Convert the week_start_dt to a Date object
          const weekStartDate = new Date(weekStatus.week_start_dt);
          // Store the status_key with the week start date as key
          weekStatusMap.set(weekStartDate.toDateString(), weekStatus.status_key);
      });

     //const cellDateKey = cellDate.toDateString();
     const cellDateKey =  moment(cellDate).format('ddd MMM DD YYYY');

      if (weekStatusMap.has(cellDateKey)) {
          const statusKey = weekStatusMap.get(cellDateKey);

          switch (statusKey) {
              case 1:
                  return 'pending-date-class';   // Add a class for blue color
              case 2:
                  return 'submitted-date-class'; // Add a class for yellow color
              case 3:
                  return 'approved-date-class';  // Add a class for green color
              case 4:
                  return 'reject-date-class';    // Add a class for red color
              default:
                  return '';
          }
      }
  }
  return '';
  };

  timeSheetStatusData:any;
  weekStatusDetails:any=[];
  async getTimesheetStatus(): Promise<void> {
    try {
      this.isLoading = true;
  
      // Wait for employee_key to load from localStorage
      const empKey = await this.empDetails.employee_key;
      if (!empKey) {
        throw new Error('Employee key not found');
      }
  
      const payload = { "employee_key": empKey };
  
      return new Promise((resolve, reject) => {
        this.apiService.getTimesheetStatus(payload).subscribe({
          next: (res: any) => {
            this.timeSheetStatusData = res;
            this.isLoading = false;
            this.weekStatusDetails = []; // Reset the array to avoid duplication
  
            this.timeSheetStatusData.forEach((element: any) => {
              element.week_start_dt = moment(element.week_start_dt).format('DD MMM YYYY');
              //push the date and status in weekStatusDetails array
              this.weekStatusDetails.push({
                week_start_dt: element.week_start_dt,
                status_key: element.status_key
              });
            });
  
            resolve(); // Resolve the promise after data is processed
          },
          error: (err) => {
            this.isLoading = false;
            reject(err); // Reject the promise if there's an error
          }
        });
      });
    } catch (error) {
      console.error('Error loading employee key or fetching timesheet status:', error);
      this.isLoading = false;
    }
  }
  getWeekEndDate(startDate: Date): Date {
    const monday = new Date(startDate);
    // Find the next Sunday (7 days from the current Monday)
    const nextsunday = new Date(monday);
    nextsunday.setDate(monday.getDate() + 6); // Add 6 days to Monday to get Sunday

    return nextsunday;
  }

  onSubmitdate(event: any,picker:any, fromChild?: any) {

    if (this.firstChange) {
      this.previousValue = this.dateRangeForm.get('startDate')?.value;
      this.firstChange = false;
    }
    
    let navigate = this.helpService.arraysEqual(this.initRowData,this.rowData)
    if(fromChild) navigate = true;
    if(!navigate&& this.helpService.status!='Submitted'){
      // this.AlertDialog.error('Do you want to leave without saving?'); return
    this.AlertDialog.confirmationWindow('Do you want to leave without saving?')
    .then(() => {
        this.isLoading = true;
        this.empDetails = sessionStorage.getItem('EmployeeData');
       // this.empDetails = (JSON.parse(this.empDetails))[0];
       if(this.empDetails) {
        try{
          this.empDetails = JSON.parse(this.empDetails)[0];
        } catch(error) {
          console.log("Error parsing Employeedata",error);
          this.empDetails = null;
        }
       } else {
        this.empDetails = null;
       }
        if (this.dateRangeForm.valid) {
            const startDate = this.dateRangeForm.value.startDate;
            const endDate = this.getWeekEndDate(startDate);
            let postData = {
                "employee_key": this.empDetails.employee_key,
                "week_start_dt": moment(startDate).format('yyyy-MM-DD'),
                "week_end_dt": moment(endDate).format('yyyy-MM-DD'),
            };
            this.selectedweeks = postData;
            this.helpService.selectedDate = moment(startDate).format(' MMM DD - ') + moment(endDate).format('MMM DD, YYYY');
            localStorage.setItem('selectedDate', moment(startDate).format(' MMM DD -') + moment(endDate).format('MMM DD YYYY'));
            sessionStorage.setItem('selectedDate', JSON.stringify(postData));
            this.getMasterTimesheet(postData);
            this.getRowData(postData);
        } else {
            this.isLoading = false;
            this.AlertDialog.error('Invalid Date');
        }
    })
    .catch(() => {
      this.dateRangeForm.controls['startDate'].setValue(this.previousValue, { emitEvent: false });
       // console.log('Cancelled!');
        // Handle the cancellation action
    });
  }else{
    this.isLoading = true
    this.empDetails = sessionStorage.getItem('EmployeeData');
    this.empDetails = (JSON.parse(this.empDetails))[0];
    if (this.dateRangeForm.valid) {
      const startDate = this.dateRangeForm.value.startDate;
      const endDate = this.getWeekEndDate(startDate);
      let postData: any = {
        "employee_key": this.empDetails.employee_key,
        "week_start_dt": moment(startDate).format('yyyy-MM-DD'),
        "week_end_dt": moment(endDate).format('yyyy-MM-DD'),
      }
      this.selectedweeks = postData
      this.helpService.selectedDate = moment(startDate).format(' MMM DD - ') + moment(endDate).format('MMM DD, YYYY')
      localStorage.setItem('selectedDate', moment(startDate).format(' MMM DD -') + moment(endDate).format('MMM DD YYYY'))
      sessionStorage.setItem('selectedDate', JSON.stringify(postData))
      this.getMasterTimesheet(postData)
      this.getRowData(postData)

    }else{
      this.isLoading = false
      this.AlertDialog.error('inValid Date')
    }}
  }

  onOpen() { //call the function
    this.appendFooter();
  }
  
  private appendFooter() { //add the footer using nativeElement
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.appendChild(this.datepickerFooter.nativeElement);
}
empCode:string = '';
noOfDelay:string = '';
empDetailsLoaded: Promise<void> | null = null;
managerEmail:string = "";
managerName:string = "";
  ngOnInit(): void {
    this.empDetailsLoaded = new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          this.empDetails = sessionStorage.getItem('EmployeeData');
          if (this.empDetails) {
            this.empDetails = JSON.parse(this.empDetails)[0];
            this.EmpName = this.empDetails.emp_frst_nm + ' ' + this.empDetails.emp_last_nm;
            this.empCode = this.empDetails.employee_key;
            this.designation = this.empDetails.designation;
            this.noOfDelay = this.empDetails.no_of_times_delayed_submission;
            this.managerEmail = this.empDetails.manager_email;
            this.managerName = this.empDetails.manager_name;
            resolve(); // Resolving promise when data is loaded successfully
          } else {
            reject('Employee data not found in session storage');
          }
        } catch (error) {
          reject(error); // Reject if there's any error
        }
      }, 5000);
    });
  }
  getRowData(postdata: any) {
    this.apiService.getTimesheet(postdata).subscribe((res) => {
      this.rowData = res ? res : []
       this.initRowData = res ? _.cloneDeep(this.helpService.replaceNullsWithPipe(res)) : []; // Use lodash's cloneDeep
       if(this.rowData.length==0){this.addRow()}
      this.getColumnDef(this.dataSet);
      this.isLoading = false;

    })
    this.showtable = true
  }

  getColumnDef(event: any) {

    let selectedweeks: any = sessionStorage.getItem('selectedDate');
    selectedweeks = JSON.parse(selectedweeks);

    //console.log("startDate: ", selectedweeks.week_start_dt, "endDate: ", endDate);
    const startDate = new Date( selectedweeks.week_start_dt);
    const endDate = new Date( selectedweeks.week_end_dt);
    // Initialize an array to hold the dynamically created objects
    const columns = [];
    // Loop through each date from startDate to endDate
    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
      const day = date.getDate();
      const month = date.toLocaleString('en-US', { month: 'short' });
      const dayName = date.toLocaleDateString('en-US', { weekday: 'short' }); //get day name
      //console.log(month);
      // Create the object for the current date
      const column = {
        headerName: ` ${day} ${month} ${dayName}`,
         headerClass: 'date-custom-header',
        wrapHeaderText: true,
        field: moment(date).format('YYYY-MM-DD'),
        minWidth: 70,
        maxWidth: 70,
        aggFunc: "sum",
        // editable:true
        cellRenderer: MatInputCellRendererComponent,
      };

      // Add the object to the columns array
      columns.push(column);
    }

    this.columnDefs = [
      {
        headerName: 'Client Name',
        minWidth: 130,
        field: 'client_key',
       // headerClass: 'date-custom-header',
        headerComponent: CustomHeaderComponent,
        cellRenderer: MatSelectCellRendererComponent,
        cellRendererParams: (params: any) => ({
          rawData: event.client_key,
          filters: {},
          props: {
            value: "client_key",
            label: "client_nm"
          }
        }),
      },
      {
        headerName: 'Brand Name',
        field: 'brand_key',
        minWidth: 130,
        headerComponent: CustomHeaderComponent,
        cellRenderer: MatSelectCellRendererComponent,
        cellRendererParams: (params: any) => ({
          rawData: event.brand_key,
          filters: {
            'client_key': params?.data?.client_key,
          },
          props: {
            value: "brand_key",
            label: "brand_nm"
          }
        })
      },
      {
        headerName: ' Project Name',
        field: 'project_key',
        minWidth: 185,
        headerComponent: CustomHeaderComponent,
        cellRenderer: MatSelectCellRendererComponent,
        cellRendererParams: (params: any) => ({
          rawData: event.project_key,
          filters: {
            'client_key': params?.data?.client_key,
            'brand_key': params?.data?.brand_key,
          },
          props: {
            value: "project_key",
            label: "project_nm"
          }
        }),
      },
      {
        headerName: 'Task Name',
        field: 'task_key',
        minWidth: 200,
        headerComponent: CustomHeaderComponent,
        cellRenderer: MatSelectCellRendererComponent,
        cellRendererParams: (params: any) => ({
          rawData: event.task_key,
          filters: {
            'client_key': params?.data?.client_key,
            'brand_key': params?.data?.brand_key,
            'project_key': params?.data?.project_key,
          },
          props: {
            value: "task_key",
            label: "task_description"
          }
        }),
      },
      {
        headerName: 'Jira #',
        field: 'jira_no',
        minWidth: 100,
        headerComponent: CustomHeaderComponent,
        cellRenderer: AutocompleteCellRenderer,
        cellRendererParams: (params: any) => ({
          rawData: event.jira_no,
          filters: {
            'project_key': params?.data?.project_key,
          },
          props: {
            value: "jira_no",
            label: "jira_task_no",
            desc:'jira_desc'
          }
        }),
      },
      ...columns,
      {
        headerName: 'Actions',
        field: 'actions',
        minWidth: 75,
        maxWidth: 75,
        headerClass: 'date-custom-header',
        cellStyle: { 'text-align': 'center', 'font-size': '14', 'font-weight': '600', 'color': '#6c757de6' },
        cellRenderer: (params: any) => {
          if (params.node.rowPinned) { return params.value ? params.value.toString() : '0' }
          let container = document.createElement('div');
          container.className = 'buttoncontainer';
          let deleteButton = document.createElement('div');
          deleteButton.title = 'Delete';
          deleteButton.innerHTML =
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="20px" height="20px" fill-rule="nonzero"><g fill="#e42222" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(8,8)"><path d="M15,4c-0.52344,0 -1.05859,0.18359 -1.4375,0.5625c-0.37891,0.37891 -0.5625,0.91406 -0.5625,1.4375v1h-6v2h1v16c0,1.64453 1.35547,3 3,3h12c1.64453,0 3,-1.35547 3,-3v-16h1v-2h-6v-1c0,-0.52344 -0.18359,-1.05859 -0.5625,-1.4375c-0.37891,-0.37891 -0.91406,-0.5625 -1.4375,-0.5625zM15,6h4v1h-4zM10,9h14v16c0,0.55469 -0.44531,1 -1,1h-12c-0.55469,0 -1,-0.44531 -1,-1zM12,12v11h2v-11zM16,12v11h2v-11zM20,12v11h2v-11z"></path></g></g></svg>';
          deleteButton.className = (this.helpService.status=='Submitted' || this.helpService.status == 'Approved') ?'delete-button-disable':'delete-button';
          deleteButton.addEventListener('mouseover', () => {
            // Your code for mouse hover event
            deleteButton.className = (this.rowData.length > 1 && this.helpService.status !== 'Submitted' && this.helpService.status !== 'Approved') 
            ? 'delete-button' 
            : 'delete-button-disable';
            deleteButton.className = this.rowData.length === 1 ? 'delete-button-disable' : deleteButton.className;
          });
          deleteButton.className = (this.rowData.length > 1 && this.helpService.status !== 'Submitted' && this.helpService.status !== 'Approved') 
          ? 'delete-button' 
          : 'delete-button-disable';
          deleteButton.className = this.rowData.length === 1 ? 'delete-button-disable' : deleteButton.className;
          deleteButton.addEventListener('click', () => {
            // Add delete logic here
           
           if(this.helpService.status=='Submitted' || this.helpService.status == 'Approved'){return}
            const index = this.rowData.indexOf(params.node.data);
            if(this.rowData.length>1){
              if (index > -1) {
                this.rowData.splice(index, 1);
              }
              params.api.applyTransaction({ remove: [params.node.data] });
              this.updateGrandTotalRow(this.gridApi)
            }
          });
          let copyButton = document.createElement('div');
          copyButton.innerHTML =
          '<svg fill="#4379F2" width="20" height="20" viewBox="0 0 36 36" version="1.1"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>clone-line</title><path d="M6,6H22v4h2V6a2,2,0,0,0-2-2H6A2,2,0,0,0,4,6V22a2,2,0,0,0,2,2h4V22H6Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M30,12H14a2,2,0,0,0-2,2V30a2,2,0,0,0,2,2H30a2,2,0,0,0,2-2V14A2,2,0,0,0,30,12Zm0,18H14V14H30Z" class="clr-i-outline clr-i-outline-path-2"></path><polygon points="21 28 23 28 23 23 28 23 28 21 23 21 23 16 21 16 21 21 16 21 16 23 21 23 21 28" class="clr-i-outline clr-i-outline-path-3"></polygon><rect x="0" y="0" width="36" height="36" fill-opacity="0"/></svg>';
          copyButton.className = 'copy-button';
          copyButton.title = 'Copy';
          copyButton.addEventListener('mouseover', () => {
            copyButton.className =(this.helpService.status=='Submitted' || this.helpService.status == 'Approved')?'copy-button-disable': 'copy-button';
          })
          copyButton.className =(this.helpService.status=='Submitted' || this.helpService.status == 'Approved')?'copy-button-disable': 'copy-button';
          copyButton.addEventListener('click', () => {
            //add copy logic here
            // Create a deep copy of the cell value
            if(this.helpService.status=='Submitted' || this.helpService.status == 'Approved'){return}
              const copiedValue = JSON.parse(JSON.stringify(params.node.data));
              // Iterate over each key in the copied value
              for (const key in copiedValue) {
                if (/^\d{4}-\d{2}-\d{2}$/.test(key)) {
                    // Set values for date properties
                    copiedValue[key] = '|';
                }
                // Set values for other properties
                if (key === 'jira_no' || key === 'work_typ_key') {
                    copiedValue[key] = "";
                }
              }
              this.rowData.push(copiedValue); //update rowData here
              this.rowData=[...this.rowData];
              const newRow = { copiedValue, [params.column.colId]: copiedValue }; // Add the copied value to a new row or handle it as needed
              params.api.applyTransaction({ add: [newRow] }); // For example, you could add a new row with the copied key with empty values
          });
          container.appendChild(deleteButton);
          container.appendChild(copyButton);

          return container;
        },
      },
    ];

    // console.log(this.columnDefs)
    // if(this.rowData.length>0){
    this.addGrandTotalRow(this.gridApi);
  // }

    this.isLoading = false
  }
  addGrandTotalRow(gridApi: any) {
    
    if(gridApi) {
      const totalRow: any = { client_key: '', brand_key: '', project_key: '', task_key: '', jira_no: 'Total ', actions: 0 };

     const val =   moment(this.selectedweeks.week_start_dt).format('YYYY-MM');
     const val2 =  moment(this.selectedweeks.week_end_dt).format('YYYY-MM');
      
      this.columnDefs.forEach((colDef: ColDef) => {
        const field = colDef.field;
        if (field && field.startsWith(val)) {
          totalRow[field] = this.rowData.reduce((acc: any, row: any) => acc + (parseFloat((row[field]?.split('|')[0])) || 0), 0);
          totalRow[field] = totalRow[field] + '|';
          totalRow['actions'] += parseFloat(totalRow[field]);
        }
        if (field && (val!=val2) && field.startsWith(val2)) {
          totalRow[field] = this.rowData.reduce((acc: any, row: any) => acc + (parseFloat((row[field]?.split('|')[0])) || 0), 0);
          totalRow[field] = totalRow[field] + '|';
          totalRow['actions'] += parseFloat(totalRow[field]);
        }
      });
      //console.log("total ", totalRow);
      
      gridApi.setPinnedBottomRowData([totalRow]);
      // gridApi.setGridOptions({ pinnedBottomRowData: [totalRow] });
    }
  }

  addRow() {
    const newRow: any = {
      client_key: null,
      brand_key: null,
      project_key: null,
      task_key: null,
      jira_no: '',
    }
    const startDate = new Date(this.selectedweeks.week_start_dt); // Start date
    const endDate = new Date(this.selectedweeks.week_end_dt); // End date

    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const key = `${year}-${month}-${day}`;
      newRow[key] = '|';
      currentDate.setDate(currentDate.getDate() + 1);
    }
    this.rowData = [...this.rowData, newRow];
  }


  onCellValueChanged(params: any): void {
    if (params.colDef.field === 'client_key') {
      params.node.setDataValue('brand_key', null);
      params.node.setDataValue('project_key', null);
      params.node.setDataValue('task_key', null);
      params.node.setDataValue('jira_no', '');
      params.api.refreshCells({ columns: ['brand_key', 'project_key', 'task_key', 'jira_no'], force: true });
    } else if (params.colDef.field === 'brand_key') {
      params.node.setDataValue('project_key', null);
      params.node.setDataValue('task_key', null);
      params.node.setDataValue('jira_no', '');
      params.api.refreshCells({ columns: ['project_key', 'task_key', 'jira_no'], force: true });
    } else if (params.colDef.field === 'project_key') {
      params.node.setDataValue('task_key', null);
      params.node.setDataValue('jira_no', '');
      params.api.refreshCells({ columns: ['task_key', 'jira_no'], force: true });
    } else if (params.colDef.field === 'task_key') {
      params.node.setDataValue('jira_no', '');
      params.api.refreshCells({ columns: ['jira_no'], force: true });
    }
    this.updateGrandTotalRow(this.gridApi)
  }

  updateGrandTotalRow(gridApi: any) {
    const totalRow: any = { client_key: '', brand_key: '', project_key: '', task_key: '', jira_no: 'Total', actions: 0 };
    const rowData: any = [];
    const val = moment(this.selectedweeks.week_start_dt).format('YYYY-MM')
    const val2 = moment(this.selectedweeks.week_end_dt).format('YYYY-MM');

    gridApi.forEachNode((node: any) => rowData.push(node.data));
    this.columnDefs.forEach((colDef: ColDef) => {
      const field = colDef.field;
      if (field && field.startsWith(val)) {
        totalRow[field] = this.rowData.reduce((acc: any, row: any) => acc + (parseFloat(row[field].split('|')[0]) || 0), 0);
        totalRow[field] = totalRow[field] + '|'
        totalRow['actions'] += parseFloat(totalRow[field])
      }
      if (field && (val!=val2) && field.startsWith(val2)) {
        totalRow[field] = this.rowData.reduce((acc: any, row: any) => acc + (parseFloat((row[field]?.split('|')[0])) || 0), 0);
        totalRow[field] = totalRow[field] + '|';
        totalRow['actions'] += parseFloat(totalRow[field]);
      }
    });
    gridApi.setPinnedBottomRowData([totalRow]);
  }


  async loadFields(fields: any) {
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];
      let opt = element.templateOptions;

      if (opt.dataSourceUrl) {
        await this.getDataForAllFields(opt.method, opt.dataSourceUrl, opt.parameter, element.key);
      }
    }

    // console.log("log", this.timesheetService.allOnLoadDataForFields);
    this.dataSet = this.timesheetService.allOnLoadDataForFields;
    this.isLoading = false
  }

  async getDataForAllFields(method: string, endPoint: string, parameter: string, key: string) {
    return new Promise((resolve, reject) => {
      this.timesheetService.getFilterFieldsData(method, endPoint, parameter, key).subscribe(
        (res: any) => {
          if (res) {
            this.timesheetService.allOnLoadDataForFields[key] = res;
             if(key=='work_typ_key') this.helpService.setWorkMode(res);
            // if(key == 'jira_no') this.callInitialDatePicker();
            this.empDetailsLoaded?.then(() => {
              if (key == 'work_typ_key') {
                this.initialize();
              }
            }).catch(error => {
              console.error("Error loading employee details:", error);
            });
          }
          resolve(res);
        },
        (error: any) => {
          this.AlertDialog.error(JSON.stringify(error))
          this.isLoading=false
          reject(error);
        }
      );
    });
  }

  async initialize() {
    try {
      await this.getTimesheetStatus();
      this.callInitialDatePicker();
    } catch (error) {
      console.error('Error fetching timesheet status:', error);
    }
  }
  async callInitialDatePicker() {
    this.activeroute.queryParams.subscribe(async (params) => {
      // Set date based on query params or default to the current week's Monday
      if (params['date']) {
        this.dateValue = params['date'];
        this.dateRangeForm.get('startDate')?.setValue(this.dateValue); 
      } else {
        const today = new Date();
        let firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
        if(this.weekStatusDetails.length ==0) { 
          //let doJ =  new Date("2024-11-08T00:00:00")
          let doJ =  new Date(this.empDetails.date_of_joining)
          console.log("doj", doJ, "firstDayOfWeek",firstDayOfWeek);
          if(doJ > firstDayOfWeek) {
            console.log("doj is bada");
            const upcomingMonday = new Date(firstDayOfWeek);
            upcomingMonday.setDate(firstDayOfWeek.getDate() + 7);
            this.dateRangeForm.get('startDate')?.setValue(upcomingMonday);
          } else {
            this.dateRangeForm.get('startDate')?.setValue(firstDayOfWeek);
          }
        } else {  
          let pastTwoWeeksDetails = this.getPastWeekDetails(firstDayOfWeek, this.weekStatusDetails);
          let earliestWeek = this.getEarliestDateWithStatus(pastTwoWeeksDetails);
          const inputDate = new Date(earliestWeek);
          if(earliestWeek == null || earliestWeek == undefined) {
            this.dateRangeForm.get('startDate')?.setValue(firstDayOfWeek);
          } else {
            this.dateRangeForm.get('startDate')?.setValue(inputDate);
          }
        }
      }
      // Call `onSubmitdate` after the date is set
      this.onSubmitdate('from email', ''); 
    });
  }

  getPastWeekDetails(
    currentWeekMonday: Date,
    weekDetailsArray: { week_start_dt: string; status_key: number }[]
  ) {
    // Calculate the two previous Mondays
    const oneWeekBeforeMonday = new Date(currentWeekMonday);
    oneWeekBeforeMonday.setDate(currentWeekMonday.getDate() - 7);
    const twoWeeksBeforeMonday = new Date(currentWeekMonday);
    twoWeeksBeforeMonday.setDate(currentWeekMonday.getDate() - 14);
  
    // Helper function to format dates to 'dd MMM yyyy'
    const formatDate = (date: Date) =>
      date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
  
    // Target dates formatted to match entries in weekDetailsArray
    const pastMondays = [formatDate(oneWeekBeforeMonday), formatDate(twoWeeksBeforeMonday)];
  
    // Find entries in the array for each past Monday and filter out any undefined entries
    const result = pastMondays
      .map((formattedDate) => 
        weekDetailsArray.find((item) => item.week_start_dt === formattedDate)
      )
      .filter((entry) => entry !== undefined); // Only include existing entries
  
    return result;
  }

  getEarliestDateWithStatus(data: any[]) {
    // Filter out entries with status_key 1 or 0
    const filteredData = data.filter(item => item.status_key === 1 || item.status_key === 0);

    // If no entries with status_key 1 or 0 are found, return null or a default value
    if (filteredData.length === 0) return null;

    // Sort filtered entries by date in ascending order
    filteredData.sort((a:any, b:any) => new Date(a.week_start_dt).getTime() - new Date(b.week_start_dt).getTime());

    // Return the week_start_dt of the earliest date
    return filteredData[0].week_start_dt;
}
  saveTimesheet(status: number) {
    if (this.rowData.length == 0) { alert('NO DATA TO SAVE'); return }
    
    if (status == 2) {  
      let validation:any = this.helpService.validateData(this.rowData)
      if(validation.condition==false){
          this.AlertDialog.error(JSON.stringify(validation.error,null, 4))
          return
      //   const isValid = this.helpService.validateData(this.rowData);
      //  if (!isValid){ this.AlertDialog.error('Data invalid'); return

     }
    }else{
      let validation:any = this.helpService.validateData(this.rowData)
      if(validation.condition==false){
          this.AlertDialog.error( JSON.stringify(validation.error,null, 4))
        return }
    }

    if (status == 2) {
      
      this.AlertDialog.confirmationWindow('Are you sure you want to '+ (status == 2?'submit':'save') +'?')
        .then(() => {
          this.isLoading = true
      this.timeSheetMasterData = sessionStorage.getItem('timeSheetMasterData');
      this.timeSheetMasterData = JSON.parse(this.timeSheetMasterData);
      let timesheetsData = this.helpService.transformData(this.rowData,'Submit')
      let postData: any = {
        ...timesheetsData,
        "week_start_dt": this.timeSheetMasterData?.[0]?.week_start_dt || this.selectedweeks.week_start_dt,
        "week_end_dt": this.timeSheetMasterData?.[0]?.week_end_dt || this.selectedweeks.week_end_dt,
        "employee_key": this.timeSheetMasterData?.[0]?.employee_key || this.selectedweeks.employee_key,
        "timesheet_key": this.timeSheetMasterData?.[0]?.timesheet_key || null,//27,
        "timesheet_nm": this.timeSheetMasterData?.[0]?.timesheet_nm || "Timesheet for Week " + moment(this.selectedweeks.week_start_dt).format('MMMDD-') + moment(this.selectedweeks.week_end_dt).format('MMMDD'), //"Timesheet for Week Jun17-Jun21",
        "status_key": status,
        "manager_email": this.managerEmail,
  
      }
      // console.log(postData)
      this.apiService.saveTimesheet(postData).subscribe(
        (res) => {
          this.isLoading = false
          this.getMasterTimesheet(this.selectedweeks)
          this.AlertDialog.success("Timesheet " + (status == 2?'submitted':'saved') + " successfully ")
          this.initRowData = res ? _.cloneDeep(this.helpService.replaceNullsWithPipe(this.rowData)):[];
          this.getTimesheetStatus();
        },
        (err) => {
          // Handle the error here
          this.isLoading = false
          this.AlertDialog.error('Error saving timesheet:' +JSON.stringify(err))
          console.error('Error saving timesheet:', err);
        }
      );
        })
        .catch(() => {
         // console.log('Cancelled!');
          return
          // Handle the cancellation action
        });
    }else{
      
      
        this.isLoading = true
    this.timeSheetMasterData = sessionStorage.getItem('timeSheetMasterData');
    this.timeSheetMasterData = JSON.parse(this.timeSheetMasterData);
    let timesheetsData = this.helpService.transformData(this.rowData,'save')
    let postData: any = {
      ...timesheetsData,
      "week_start_dt": this.timeSheetMasterData?.[0]?.week_start_dt || this.selectedweeks.week_start_dt,
      "week_end_dt": this.timeSheetMasterData?.[0]?.week_end_dt || this.selectedweeks.week_end_dt,
      "employee_key": this.timeSheetMasterData?.[0]?.employee_key || this.selectedweeks.employee_key,
      "timesheet_key": this.timeSheetMasterData?.[0]?.timesheet_key || null,//27,
      "timesheet_nm": this.timeSheetMasterData?.[0]?.timesheet_nm || "Timesheet for Week " + moment(this.selectedweeks.week_start_dt).format('MMMDD-') + moment(this.selectedweeks.week_end_dt).format('MMMDD'), //"Timesheet for Week Jun17-Jun21",
      "status_key": status,
      "manager_email": this.managerEmail,
      "manager_name":this.managerName
    }
    // console.log(postData)
    this.apiService.saveTimesheet(postData).subscribe(
      (res) => {
        this.isLoading = false
        this.getMasterTimesheet(this.selectedweeks)
        this.AlertDialog.success("Timesheet " + (status == 2?'submited':'saved') + " successfully ")
        this.initRowData = res ? _.cloneDeep(this.helpService.replaceNullsWithPipe(this.rowData)):[];
      },
      (err) => {
        // Handle the error here
        this.isLoading = false
        // this.AlertDialog.error('Error saving timesheet:' +JSON.stringify(err))
        this.AlertDialog.error('Error saving timesheet')
        console.error('Error saving timesheet:', err);
      }
    );
      

    }
  
  }

  userModifiedDate:any;
  getMasterTimesheet(postData: any) {

    this.apiService.getMasterTimesheet(postData).subscribe((res: any) => {
      let data: any = res ? res : [];
      // console.log("data", data, "postData", postData);
       this.setMangerDateLabelComments(postData,data);
      let status:any= data.length>0?this.status?.find((d:any)=> data?.[0]?.status_key==d.status_key):"New"
      this.helpService.status = data.length>0?status.status_desc:'New'
      // console.log(data[0]?.status_key,this.helpService.status)
      this.userModifiedDate = data.length>0?data[0]?.modified_dt: this.today 
      sessionStorage.setItem('timeSheetMasterData', JSON.stringify(data))

    },(err)=>{this.AlertDialog.error(JSON.stringify(err))})
  }

  setMangerDateLabelComments(postData: any, res: any) { //set data label for manager comments as per week_start and week_end date
    const weekArray: string[] = [];
    const startDate = new Date(postData.week_start_dt);
    const endDate = new Date(postData.week_end_dt);
    //this.helpService.setComment('','');

    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      weekArray.push(formattedDate);
    }
    //console.log("weekArray", weekArray);
     // Split the comments string into an array
     const comments = res[0]?.comments || ""; // Fallback to empty string if comments are not available
     const commentsArray = comments.split('|').map((comment:any) => comment.trim());
 
     // Create an object to hold the comments by date
     const commentsByDate: { [key: string]: string } = {};
 
     // Assign comments to the corresponding date in weekArray
     weekArray.forEach((date, index) => {
      const comment = commentsArray[index] || ""; // Get the comment or an empty string
      this.helpService.setComment(date, comment); // Assign comment or empty if none exists
     });
  }
submitTimesheet(status:any){
   
    let  pinnedBottomRowNodes:any  = this.gridApi.getPinnedBottomRow(0) ;
    let validation=this.validateData(pinnedBottomRowNodes.data)
    if(validation?.action==false){
    this.AlertDialog.error('Please ensure weekly total hours ≥ 40 and daily hours ≥ 8  (e.g.,'+ moment(validation.label).format('DD MMM') +'='+validation.value+')' )
    return
    }

this.saveTimesheet(status)

}
 validateData(data:any):any {
     
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  const keys = Object.keys(data);
  //const lastTwoDays = keys.slice(-2); // Get the weekend of selected week 
  for (let key in data) {
      if (datePattern.test(key)) {
          const value = parseInt(data[key].split('|')[0], 10);
          const date = new Date(key);
          const dayOfWeek = date.getDay();
          if (dayOfWeek === 0 || dayOfWeek === 6) { // Check if the date is weekends
            if (value > 8) { // no minimum hour to log but maximum is 8
                return { action: false, label: key, value: value };
            }
        } else {
            if (value < 8) { // for week days minimum is 8
                return { action: false, label: key, value: value };
            }
        }
      }
  }
 
}


  openCloneWeekModal() {
    this.timeSheetMasterData = sessionStorage.getItem('timeSheetMasterData');
    this.timeSheetMasterData = JSON.parse(this.timeSheetMasterData);
    this.passToClone= {
      "week_start_dt": this.timeSheetMasterData?.[0]?.week_start_dt || this.selectedweeks.week_start_dt,
      "week_end_dt": this.timeSheetMasterData?.[0]?.week_end_dt || this.selectedweeks.week_end_dt,
      "employee_key": this.timeSheetMasterData?.[0]?.employee_key || this.selectedweeks.employee_key,
      "timesheet_key": this.timeSheetMasterData?.[0]?.timesheet_key || null,//27,
      "timesheet_nm": this.timeSheetMasterData?.[0]?.timesheet_nm || "Timesheet for Week " + moment(this.selectedweeks.week_start_dt).format('MMMDD-') + moment(this.selectedweeks.week_end_dt).format('MMMDD'),
    }
    this.isModalOpen = true;
  }

  receiveSelectedData(selectedData: any) {
    console.log('Selected data:', selectedData);
    if (selectedData === 'Done') {
     // window.location.reload();

      this.onSubmitdate(this.dateRangeForm.value.startDate,this.dateRangeForm.value.startDate,'fromChild');
    }
    // Handle the selected data as needed
  }

  closeModal() {
    this.isModalOpen = false;
  }



alertrowdata(){
var obj = {data:this.rowData};
alert(JSON.stringify(obj,null, 4));}

ngOnDestroy() {
  // Emit a value to complete all subscriptions
  this.destroy$.next();
  this.destroy$.complete();
  this.helpService.status = '';
}
}
