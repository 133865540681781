import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, catchError, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  getResourceListByManagerUrl:string='hr-api/timesheet/get-manager-resources';
  getSubmittedTimesheetListUrl:string='hr-api/timesheet/get-timesheet-manager';
  updateAndRejectTimesheetUrl:string='hr-api/timesheet/update-timesheet-status';

  private employeeTimesheetSubject = new BehaviorSubject<any[]>([]);
  data$: Observable<any[]> = this.employeeTimesheetSubject.asObservable();

  constructor( private apiService: ApiService,private http: HttpClient) { }

  setData(data: any[]): void {
    this.employeeTimesheetSubject.next(data);
  }

  getData(): Observable<any[]> {
    return this.data$;
  }
  getResourceList(payload:any) {
    return this.apiService.post(this.getResourceListByManagerUrl,payload)
  }

  getSubmittedTimesheetList(payload:any) {
    return this.apiService.post(this.getSubmittedTimesheetListUrl,payload)
  }

  updateAndRejectTimesheet(payload:any) {
    return this.apiService.post(this.updateAndRejectTimesheetUrl,payload)
  }

  getVideoLink() {
    return this.apiService.get(`hr-api/trainings/get-training-links`);
  }

  gettrainingStructured() {
    return this.apiService.get(`hr-api/trainings/get-trainings`);
  }

  getSelectedVideo(payload:any) {
    return this.http.post('https://platform-stage-api.dataunveil.com/hr-api/trainings/get-training-url', payload, { responseType: 'text' })
    .pipe(
      // Handle response as text
      catchError(this.handleError),
      // Attempt to parse the response as JSON if it's a text response
      catchError(response => {
        try {
          return of(JSON.parse(response));
        } catch (e) {
          return throwError(() => new Error('Invalid JSON or error response: ' + response));
        }
      })
    );
  }

  getEmployeeHeadshot(data:any): Observable<any> {
    return this.http.post(`https://platform-stage-api.dataunveil.com/hr-api/user/get-user-image-path`,data, { responseType: 'text' })
    .pipe(
      catchError(this.handleError),
      catchError(response => {
        try {
          return of(JSON.parse(response));
        } catch (e) {
          return throwError(() => new Error('Invalid JSON or error response: ' + response));
        }
      })
    )
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Server error code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => new Error(errorMessage));
  }

  getManagerTimesheet(data:any): Observable<any> {
    return this.apiService.post(`hr-api/timesheet/get-week-timesheet-manager`,data)
  }

  getToCloneWeekData(payload:any): Observable<any> {
    return this.apiService.post(`hr-api/timesheet/get-week-timesheet-to-clone`,payload)
  }

  postCloneWeekData(payload:any): Observable<any> {
    return this.apiService.post(`hr-api/timesheet/clone-weektimesheet-details`,payload)
  }

}