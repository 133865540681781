

<mat-toolbar color="primary" class="header" style="background: linear-gradient(90deg, rgba(174,184,210,1) 0%, rgba(158,70,134,1) 54%, rgba(9,56,104,1) 100%)">
  <!-- Left side: Profile Name and Logout Menu -->
  <div class="left-section profile">
    <img src="assets/svg/all.svg" alt="Profile Picture" class="profile-img" />
    <span class="font-family">{{ userName }}</span>
    <button mat-icon-button #logoutMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="logoutMenu">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <!-- Logout Menu -->
    <mat-menu #logoutMenu="matMenu">
      <!-- <button mat-menu-item (click)="logout()" class="font-family">Logout</button> -->
      <button mat-menu-item (click)="logout()" ><span style="font-family: 'poppins' !important;">Logout</span></button>
    </mat-menu>
  </div>

  <div class="menu-section">
    <!-- SVG Icon on the left of the Menu button -->
    <span class="svg-container" #helpMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="helpMenu">
      <svg width="24" height="24" viewBox="0 0 16 16" fill="rgba(158, 70, 134, 1)" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3542_8263)">
          <path d="M11.163 12.6883C7.69268 16.1586 6.95514 16.2887 3.11074 12.4443C-0.733673 8.59984 -0.607857 7.86655 3.11074 4.14796C6.82933 0.429364 7.42602 0.166942 11.407 4.14796C15.388 8.12897 15.2514 8.59984 11.163 12.6883Z" fill="#1363DF"/>
          <path d="M7.25888 5.66665C7.46138 5.66665 7.62553 5.5025 7.62553 5.3C7.62553 5.09751 7.46138 4.93335 7.25888 4.93335C7.05639 4.93335 6.89224 5.09751 6.89224 5.3C6.89224 5.5025 7.05639 5.66665 7.25888 5.66665Z" fill="white"/>
          <path d="M7.25888 11.533V8.59983M11.163 12.6883C7.69268 16.1586 6.95514 16.2887 3.11074 12.4443C-0.733673 8.59984 -0.607857 7.86655 3.11074 4.14796C6.82933 0.429364 7.42602 0.166942 11.407 4.14796C15.388 8.12897 15.2514 8.59984 11.163 12.6883ZM7.62553 5.3C7.62553 5.5025 7.46138 5.66665 7.25888 5.66665C7.05639 5.66665 6.89224 5.5025 6.89224 5.3C6.89224 5.09751 7.05639 4.93335 7.25888 4.93335C7.46138 4.93335 7.62553 5.09751 7.62553 5.3Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_3542_8263">
            <rect width="16" height="16" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </span>
    <!-- Help Menu -->
    <mat-menu #helpMenu="matMenu">
      <button mat-menu-item  (click)="openDialog('docs')"><span style="font-family: 'Poppins'">Docs</span></button>
      <button mat-menu-item (click)="openDialog('video')"><span style="font-family: 'Poppins'">Video</span></button>
    </mat-menu>

    <!-- Right side: Menu Icon to Open Navigation List -->
    <button style="color: white;" id="sidenav" *ngIf="!isBurgerMenu" mat-icon-button (click)="toggleSideNav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</mat-toolbar>

<!-- Side Navigation -->
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="over" position="end" [opened]="sideNavOpen" (closedStart)="closeSideNav()">
    <mat-nav-list>
      <a *ngFor="let link of navLinks"
        mat-list-item
        [routerLink]="link.disabled ? null : link.path"  
        [routerLinkActive]="['active']"
        [class.disabled]="link.disabled || isActiveRoute(link.path)"
        (click)="onLinkClick($event, link)">
        <img [src]="link.imageUrl" alt="{{ link.label }}" class="round-image">
        <span class="text-font font-family">{{ link.label }}</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>

